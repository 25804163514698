import React from "react";
import { Button, Result } from "antd";
import { RobotOutlined } from "@ant-design/icons";

import "./styles.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-found-container">
      <Result
        icon={<RobotOutlined />}
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={() => (window.location = "/start")}>
            Start over
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
