import dayjs from "dayjs";

import { convertFromSats, createJwalletMemo } from "../helpers/convert";
import { availableBank } from "../constant/core";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const sorterByTime = (arr, sortedField, dateFormat) => {
  const arrTemp = [...arr];

  const dateSorter = (a, b) => {
    const dateA = dayjs(a[sortedField], dateFormat);
    const dateB = dayjs(b[sortedField], dateFormat);

    // Correctly compare dates for descending order
    return dateB - dateA;
  };

  return arrTemp.sort(dateSorter);
};

const getSenderUsername = (senderWallet, receiverWallet) => {
  const senderObject = receiverWallet.find((e) => e[1].wallet === senderWallet);

  if (senderObject) {
    return senderObject[1].userName;
  }

  return null;
};

export const normalizeHashrateHistory = (data) => {
  const time = [];
  const accepted = [];
  const rejected = [];

  if (data) {
    data.forEach((history) => {
      time.push(history.hour);
      accepted.push(Number(history.hashrate).toFixed(2));
      rejected.push(Number(history.rejected).toFixed(2));
    });
  }

  return { time, accepted, rejected };
};

export const normalizeMinerData = (data) => {
  const minerDataTemp = { ...data };
  minerDataTemp.totalRevenue = convertFromSats(
    data.balance + data.totalWithdrawn
  );
  minerDataTemp.balanceConvert = convertFromSats(data.balance);
  minerDataTemp.totalWithdrawnConvert = convertFromSats(data.totalWithdrawn);
  minerDataTemp.totalReceivedSharedRevenue = convertFromSats(
    data.totalReceivedSharedRevenue
  );
  // map bank and ewallet list
  minerDataTemp.idrSavedAccount = data.bankAddress.map((e) => ({
    ...e,
    value: e.accountNumber,
    label: `${e.name} - ${e.accountNumber}`,
    type: availableBank.hasOwnProperty(e.bankName) ? "bank" : "ewallet",
  }));

  // map crypto list

  minerDataTemp.cryptoWalletList = data.savedWalletAddress.map((e) => ({
    ...e,
    value: e.address,
    label: e.name,
  }));

  minerDataTemp.yesterdayRevenue = convertFromSats(data.yesterdayRevenue);

  const transactionList = (data.transactions || [])
    .filter((e) => e.txid !== "pre transfer")
    .map((e) => {
      const actionParse = JSON.parse(e.action);
      const currencyParse = JSON.parse(e.currency);

      const timestampInMilliseconds = Number(e.time) / 1e6;
      const date = new Date(timestampInMilliseconds);
      return {
        key: Number(e.id),
        no: Number(e.id),
        time: dayjs(date).format("MM/DD/YYYY"),
        activity: actionParse.action,
        amount: convertFromSats(e.amount),
        provider: currencyParse?.chain || currencyParse.bank,
        address: actionParse.receiver,
        trxId: e.txid,
        currency: currencyParse.currency,
      };
    });

  const revenueHistory = (data.revenueHistory || []).map((e, idx) => {
    let convertTime = Number(e.time || 0);

    if (e.time < 10000000000000) {
      convertTime = (Number(e.time || 0) + (24 + 7) * 60 * 60 * 1000) * 1000000;
    }

    const timestampInMilliseconds = convertTime / 1e6;
    const date = new Date(timestampInMilliseconds);

    return {
      no: idx + 1,
      hashrate: Number(e.hashrate) / 1e12,
      sats: convertFromSats(e.sats),
      time: dayjs(date).format("D/M/YY, HH:mm"),
      from: getSenderUsername(e.from, data.receivedShareList),
    };
  });

  minerDataTemp.transactionlist = sorterByTime(
    transactionList,
    "time",
    "MM/DD/YYYY"
  );

  minerDataTemp.revenueHistory = sorterByTime(
    revenueHistory,
    "time",
    "D/M/YY, HH:mm"
  );

  minerDataTemp.shareList = (data.shareList || []).map((e) => {
    const convertRevenue = e[1].sharePercent
      ? Number(e[1].sharePercent) / 100
      : 0;
    return {
      name: e[1].userName,
      percentage: convertRevenue,
    };
  });

  minerDataTemp.receivedShareList = (data.receivedShareList || []).map((e) => {
    const convertRevenue = e[1].sharePercent
      ? Number(e[1].sharePercent) / 100
      : 0;
    return {
      name: e[1].userName,
      percentage: convertRevenue,
    };
  });

  minerDataTemp.totalSharedPercent = Number(data.totalSharedPercent) / 100;

  return minerDataTemp;
};

export const generatePayloadWithDraw = (type, userName, formData) => {
  const {
    walletAddress,
    amount,
    priceInUsd,
    beneficiaryId,
    quoteId,
    aliasName,
    accountNumber,
    provider,
  } = formData || {};

  const satsAmount = parseInt(amount * 100000000);

  if (type === "USDT") {
    return {
      withdraw: [userName, satsAmount, walletAddress, priceInUsd],
      save: [aliasName, walletAddress, type],
    };
  } else if (type === "CKBTC") {
    return {
      withdraw: [userName, satsAmount, walletAddress],
      save: [aliasName, walletAddress, type],
    };
  } else if (type === "IDR") {
    const jwalletMemeo = createJwalletMemo(quoteId, beneficiaryId);
    return {
      withdraw: [quoteId, satsAmount, provider, jwalletMemeo],
      save: [aliasName, accountNumber, provider, beneficiaryId],
    };
  } else {
    return {
      withdraw: [],
      save: [],
    };
  }
};
