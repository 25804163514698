import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ScreenLoading from "./components/ScreenLoading";
import ErrorBoundary from "./containers/ErrorBoundary";
import NotFoundPage from "./containers/NotFoundPage";

import { AppProvider } from "./context";
import { SiwbIdentityProvider } from "ic-use-siwb-identity";
import { idlFactory as siwbIdl } from "./idls/ic_siwb_provider.idl.ts";

const Landing = lazy(() => import("./containers/Landing"));
const MainLayout = lazy(() => import("./layouts/MainLayout"));
const Mining = lazy(() => import("./containers/Mining"));
const Revenue = lazy(() => import("./containers/Revenue"));
const Calculator = lazy(() => import("./containers/Calculator"));
const AccountSetting = lazy(() => import("./containers/AccountSetting"));
const RevenueComparation = lazy(() =>
  import("./containers/RevenueComparation")
);

const queryClient = new QueryClient();

const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <SiwbIdentityProvider
          canisterId={"6ygg4-rqaaa-aaaai-ap7ma-cai"}
          idlFactory={siwbIdl}
          httpAgentOptions={{
            host: "https://ic0.app",
          }}
        >
          <AppProvider>
            <ErrorBoundary>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<ScreenLoading />}>
                      <RevenueComparation />
                    </Suspense>
                  }
                />

                <Route
                  path="/start"
                  element={
                    <Suspense fallback={<ScreenLoading />}>
                      <Landing />
                    </Suspense>
                  }
                />

                <Route
                  element={
                    <Suspense fallback={<ScreenLoading />}>
                      <MainLayout />
                    </Suspense>
                  }
                >
                  <Route
                    path="mining"
                    element={
                      <Suspense fallback={<ScreenLoading />}>
                        <Mining />
                      </Suspense>
                    }
                  />
                  <Route
                    path="revenue"
                    element={
                      <Suspense fallback={<ScreenLoading />}>
                        <Revenue />
                      </Suspense>
                    }
                  />
                  <Route
                    path="calculator"
                    element={
                      <Suspense fallback={<ScreenLoading />}>
                        <Calculator />
                      </Suspense>
                    }
                  />
                  <Route
                    path="account-setting"
                    element={
                      <Suspense fallback={<ScreenLoading />}>
                        <AccountSetting />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </ErrorBoundary>
          </AppProvider>
        </SiwbIdentityProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
