import React from "react";
import { theme } from "antd";
import { InfinitySpin } from "react-loader-spinner";

import useGetScreenSize from "../../hooks/useGetScreenSize";

import "./styles.scss";

const ScreenLoading = () => {
  const {
    token: { primary },
  } = theme.useToken();
  const screenHeight = useGetScreenSize();

  return (
    <div className="screen-loading-container" style={{ height: screenHeight }}>
      <InfinitySpin visible={true} color={primary} ariaLabel="loading" />
    </div>
  );
};

export default ScreenLoading;
