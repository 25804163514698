import axios from "axios";

export const getHashrateHistory = (username) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/histories/${username}`;
  return axios.get(endpoint);
};

export const getMinerInfo = (username) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/rigs/${username}`;
  return axios.get(endpoint);
};

export const lastEarning = async (username) => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/last-earning/${username}`;
    const res = await axios.get(endpoint);
    if (res?.data?.earningPerTh) {
      return res.data.earningPerTh;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export const deleteWorker = (username, workerId) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/rig/${username}/${workerId}`;
  return axios.delete(endpoint);
};

export const submitTelegramChatId = (payload) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/telegram`;
  return axios.post(endpoint, payload);
};

export const getEstimatedPoolProfit = (username) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/transaction/estimated`;
  return axios.get(endpoint);
};

export const storeLog = (payload) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/debugging-log`;
  return axios.post(endpoint, payload);
};

export const getPoolList = () => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/stratum/pool`;
  return axios.get(endpoint);
};

export const switchPool = (payload) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/stratum/change-pool`;
  return axios.put(endpoint, payload);
};
