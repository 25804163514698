export const convertFromSats = (val) => {
  if (!val) return 0;
  const result = Number(val) / 100000000;
  return result.toFixed(8);
};

export const convertToSats = (val) => {
  if (!val) return 0;

  return parseInt(val * 100000000);
};

function uuidToByteArray(uuid) {
  const noHyphensUuid = uuid.replace(/-/g, "");
  const buffer = new Uint8Array(16);
  for (let i = 0, j = 0; i < 32; i += 2, j++) {
    const byte = noHyphensUuid.substring(i, i + 2);
    buffer[j] = parseInt(byte, 16);
  }

  return buffer;
}

export const createJwalletMemo = (quoteId, beneficiaryId) => {
  const byteArray1 = uuidToByteArray(quoteId);
  const byteArray2 = uuidToByteArray(beneficiaryId);
  const concatenatedArray = new Uint8Array(32);

  concatenatedArray.set(byteArray1);
  concatenatedArray.set(byteArray2, 16);

  return concatenatedArray;
};

function toSuperscript(num) {
  const superscripts = {
    0: "⁰",
    1: "¹",
    2: "²",
    3: "³",
    4: "⁴",
    5: "⁵",
    6: "⁶",
    7: "⁷",
    8: "⁸",
    9: "⁹",
    "-": "⁻",
  };

  return String(num)
    .split("")
    .map((char) => superscripts[char] || char)
    .join("");
}

export const formatNumberWithExponent = (num) => {
  if (num === 0) return "0";

  const str = num.toString();
  const decimalIndex = str.indexOf(".");

  if (decimalIndex === -1) {
    return str;
  }

  let leadingZeros = 0;
  for (let i = decimalIndex + 1; i < str.length; i++) {
    if (str[i] !== "0") {
      break;
    }
    leadingZeros++;
  }

  const significantDigits = str.slice(decimalIndex + leadingZeros + 1);
  const exponent = leadingZeros; // Jumlah nol yang dihapus

  return `0.${"0"}${toSuperscript(exponent)}${significantDigits}`;
};
