import axios from "axios";

export const handleLogin = (payload) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/auth/login`;
  return axios.post(endpoint, payload);
};

export const handleRegister = (payload) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/auth/register`;
  return axios.post(endpoint, payload);
};

export const checkUsernameExist = (username) => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/v1/user/${username}/exists`;
  return axios.get(endpoint);
};


