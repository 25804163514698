import { Secp256k1KeyIdentity } from "@dfinity/identity-secp256k1";
import { Principal } from "@dfinity/principal";
import { fromHexString } from "@dfinity/candid/lib/cjs/utils/buffer";
import { createActor } from "../ic/icloka";
import { createActor as createActorCKBTC } from "../ic/icLedgerCkbtc";

export const getUserIdentity = (privKey) => {
  try {
    const userIdentity = Secp256k1KeyIdentity.fromSecretKey(
      fromHexString(privKey)
    );

    return userIdentity;
  } catch (error) {
    return error;
  }
};

export const getUserPrincipal = (privKey) => {
  try {
    const userIdentity = Secp256k1KeyIdentity.fromSecretKey(
      fromHexString(privKey)
    );

    return userIdentity.getPrincipal();
  } catch (error) {
    return error;
  }
};

export const getUserPrincipalFromText = (walletAddress) => {
  try {
    return Principal.fromText(walletAddress);
  } catch (error) {
    return error;
  }
};

export const lokaMinerAgentCreation = (privKey) => {
  try {
    const userIdentity = getUserIdentity(privKey);

    const userLokaIdentity = createActor(
      process.env.REACT_APP_LOKA_MINER_CANISTER_ID,
      {
        identity: userIdentity,
      }
    );

    return userLokaIdentity;
  } catch (error) {
    return error;
  }
};

export const lokaMinerSiwbAgentCreation = (identity) => {
  try {
    const userLokaIdentity = createActor(
      process.env.REACT_APP_LOKA_MINER_CANISTER_ID,
      {
        identity,
      }
    );

    return userLokaIdentity;
  } catch (error) {
    return error;
  }
};

export const ckBTCAgentCreation = (privKey) => {
  try {
    const userIdentity = getUserIdentity(privKey);

    const userckBTCIdentity = createActorCKBTC(
      process.env.REACT_APP_CKBTC_LEDGER_ID,
      {
        identity: userIdentity,
      }
    );

    return userckBTCIdentity;
  } catch (error) {
    return error;
  }
};

export const ckBTCSiwbAgentCreation = (identity) => {
  try {
    const userckBTCIdentity = createActorCKBTC(
      process.env.REACT_APP_CKBTC_LEDGER_ID,
      {
        identity,
      }
    );

    return userckBTCIdentity;
  } catch (error) {
    return error;
  }
};
