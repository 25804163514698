export const idlFactory = ({ IDL }) => {
  const DistributionHistory = IDL.Record({
    hashrate: IDL.Nat,
    from: IDL.Text,
    sats: IDL.Nat,
    time: IDL.Int,
    fromUsername: IDL.Text,
  });
  const BankAddress = IDL.Record({
    name: IDL.Text,
    jwalletId: IDL.Text,
    bankName: IDL.Text,
    accountNumber: IDL.Text,
  });
  const WalletAddress = IDL.Record({
    name: IDL.Text,
    currency: IDL.Text,
    address: IDL.Text,
  });
  const RevenueShare = IDL.Record({
    userName: IDL.Text,
    sharePercent: IDL.Nat,
    wallet: IDL.Text,
  });
  const TransactionHistory = IDL.Record({
    id: IDL.Nat,
    action: IDL.Text,
    time: IDL.Int,
    txid: IDL.Text,
    currency: IDL.Text,
    amount: IDL.Text,
  });
  const MinerData = IDL.Record({
    id: IDL.Nat,
    verified: IDL.Bool,
    username: IDL.Text,
    balance: IDL.Nat,
    hashrate: IDL.Nat,
    revenueHistory: IDL.Vec(DistributionHistory),
    bankAddress: IDL.Vec(BankAddress),
    walletAddressText: IDL.Text,
    savedWalletAddress: IDL.Vec(WalletAddress),
    yesterdayRevenue: IDL.Nat,
    walletAddress: IDL.Principal,
    receivedShareList: IDL.Vec(IDL.Tuple(IDL.Text, RevenueShare)),
    totalSharedPercent: IDL.Nat,
    totalWithdrawn: IDL.Nat,
    shareList: IDL.Vec(IDL.Tuple(IDL.Text, RevenueShare)),
    totalReceivedSharedRevenue: IDL.Nat,
    transactions: IDL.Vec(TransactionHistory),
  });
  const WithdrawalHistory = IDL.Record({
    id: IDL.Nat,
    action: IDL.Text,
    username: IDL.Text,
    memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
    time: IDL.Int,
    txid: IDL.Text,
    jwalletId: IDL.Text,
    bankId: IDL.Text,
    currency: IDL.Text,
    wallet: IDL.Text,
    amount: IDL.Text,
  });
  const Distribution = IDL.Record({
    id: IDL.Nat,
    hashrate: IDL.Text,
    data: IDL.Text,
    sats: IDL.Text,
    time: IDL.Text,
  });
  const ErrorLog = IDL.Record({
    id: IDL.Nat,
    username: IDL.Text,
    time_text: IDL.Text,
    time: IDL.Int,
    error: IDL.Text,
    wallet: IDL.Text,
  });
  const HttpHeader = IDL.Record({ value: IDL.Text, name: IDL.Text });
  const HttpResponsePayload = IDL.Record({
    status: IDL.Nat,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    context: IDL.Vec(IDL.Nat8),
    response: HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    status: IDL.Nat,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HttpHeader),
  });
  const Utxo = IDL.Record({
    height: IDL.Nat32,
    value: IDL.Nat64,
    outpoint: IDL.Record({ txid: IDL.Vec(IDL.Nat8), vout: IDL.Nat32 }),
  });
  const UtxoStatus = IDL.Variant({
    ValueTooSmall: Utxo,
    Tainted: Utxo,
    Minted: IDL.Record({
      minted_amount: IDL.Nat64,
      block_index: IDL.Nat64,
      utxo: Utxo,
    }),
    Checked: Utxo,
  });
  const PendingUtxo = IDL.Record({
    confirmations: IDL.Nat32,
    value: IDL.Nat64,
    outpoint: IDL.Record({ txid: IDL.Vec(IDL.Nat8), vout: IDL.Nat32 }),
  });
  const UpdateBalanceError = IDL.Variant({
    GenericError: IDL.Record({
      error_message: IDL.Text,
      error_code: IDL.Nat64,
    }),
    TemporarilyUnavailable: IDL.Text,
    AlreadyProcessing: IDL.Null,
    NoNewUtxos: IDL.Record({
      required_confirmations: IDL.Nat32,
      pending_utxos: IDL.Opt(IDL.Vec(PendingUtxo)),
      current_confirmations: IDL.Opt(IDL.Nat32),
    }),
  });
  const TransferRes = IDL.Variant({ error: IDL.Text, success: IDL.Nat });
  return IDL.Service({
    access: IDL.Func([], [IDL.Bool], []),
    addTestUser: IDL.Func([], [IDL.Bool], []),
    adjustB: IDL.Func([IDL.Text, IDL.Nat], [IDL.Nat], []),
    adjustMiner: IDL.Func([IDL.Text, IDL.Text], [IDL.Nat], []),
    adjustMinerBalance: IDL.Func([IDL.Text], [IDL.Text], []),
    backupUserData: IDL.Func([], [IDL.Vec(MinerData)], []),
    clearData: IDL.Func([], [], []),
    clearDistribution: IDL.Func([], [], []),
    compareNow: IDL.Func([], [IDL.Bool], []),
    differData: IDL.Func([], [IDL.Text], []),
    distributeMiningRewards: IDL.Func([IDL.Text], [IDL.Text], []),
    distributeSpecialReward: IDL.Func([IDL.Text], [IDL.Text], []),
    enableDistribution: IDL.Func([IDL.Bool], [IDL.Bool], []),
    estimateWithdrawalFee: IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    failedWithdrawalList: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, WithdrawalHistory))],
      ["query"]
    ),
    fetchMinerById: IDL.Func([IDL.Nat], [MinerData], ["query"]),
    fetchMinerByPrincipal: IDL.Func(
      [IDL.Principal],
      [IDL.Variant({ ok: MinerData, none: IDL.Nat })],
      ["query"]
    ),
    fetchMinerByUsername: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ ok: MinerData, none: IDL.Nat })],
      ["query"]
    ),
    forceRecordJwalletId: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Text],
      [IDL.Bool],
      []
    ),
    forcelogTransaction: IDL.Func(
      [IDL.Nat, IDL.Text, IDL.Text, IDL.Text, IDL.Text],
      [IDL.Vec(TransactionHistory)],
      []
    ),
    getAllBalance: IDL.Func([], [IDL.Nat], []),
    getAllBalances: IDL.Func(
      [],
      [
        IDL.Record({
          total: IDL.Nat,
          currentBalance: IDL.Nat,
          claimables: IDL.Nat,
          withdrawn: IDL.Nat,
          ckBTCBalance: IDL.Nat,
        }),
      ],
      []
    ),
    getAllJwalletId: IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))], []),
    getAllRevenue: IDL.Func([], [IDL.Nat], []),
    getAllRevenueRaw: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(DistributionHistory)))],
      []
    ),
    getAllWithdrawals: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, WithdrawalHistory))],
      []
    ),
    getBalance: IDL.Func(
      [],
      [
        IDL.Record({
          total: IDL.Nat,
          currentBalance: IDL.Nat,
          claimables: IDL.Nat,
          withdrawn: IDL.Nat,
          ckBTCBalance: IDL.Nat,
        }),
      ],
      ["query"]
    ),
    getCKBTCBalance: IDL.Func([], [IDL.Nat], []),
    getCKBTCMinter: IDL.Func([], [IDL.Text], []),
    getCounter: IDL.Func([], [IDL.Nat], ["query"]),
    getCurrentIndex: IDL.Func([], [IDL.Nat], ["query"]),
    getCurrentScheduler: IDL.Func([], [IDL.Nat], []),
    getDistributionList: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, Distribution))],
      ["query"]
    ),
    getErrorLogs: IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, ErrorLog))], []),
    getICPTimeString: IDL.Func([], [IDL.Text], []),
    getJwalletId: IDL.Func(
      [IDL.Text, IDL.Text],
      [IDL.Variant({ none: IDL.Text, uuid: IDL.Text })],
      []
    ),
    getMinerData: IDL.Func(
      [],
      [IDL.Variant({ ok: MinerData, none: IDL.Nat })],
      ["query"]
    ),
    getNext: IDL.Func([], [IDL.Int], []),
    getReceivedShareList: IDL.Func(
      [],
      [
        IDL.Vec(
          IDL.Tuple(IDL.Text, IDL.Vec(IDL.Tuple(IDL.Text, RevenueShare)))
        ),
      ],
      []
    ),
    getShareList: IDL.Func(
      [],
      [
        IDL.Vec(
          IDL.Tuple(IDL.Text, IDL.Vec(IDL.Tuple(IDL.Text, RevenueShare)))
        ),
      ],
      []
    ),
    getStamp: IDL.Func([], [IDL.Int], []),
    getTotalLokaRevenue: IDL.Func(
      [],
      [
        IDL.Record({
          total: IDL.Nat,
          details: IDL.Vec(IDL.Tuple(IDL.Text, Distribution)),
        }),
      ],
      []
    ),
    getTotalLokaRevenueUser: IDL.Func(
      [IDL.Text],
      [IDL.Record({ total: IDL.Nat, userTotal: IDL.Nat })],
      []
    ),
    getTotalRevenue: IDL.Func(
      [IDL.Text],
      [
        IDL.Record({
          num: IDL.Nat,
          dets: IDL.Vec(DistributionHistory),
        }),
      ],
      []
    ),
    getTotalRevenueUser: IDL.Func(
      [IDL.Text],
      [
        IDL.Record({
          num: IDL.Nat,
          dets: IDL.Vec(DistributionHistory),
        }),
      ],
      []
    ),
    getTotalRevenueUserShare: IDL.Func(
      [IDL.Text, IDL.Text],
      [
        IDL.Record({
          num: IDL.Nat,
          dets: IDL.Vec(IDL.Record({ sats: IDL.Nat })),
        }),
      ],
      []
    ),
    getTransaction: IDL.Func([], [IDL.Vec(TransactionHistory)], []),
    getUsername: IDL.Func(
      [],
      [
        IDL.Record({
          total: IDL.Nat,
          detail: IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat)),
        }),
      ],
      []
    ),
    getUsernameMapping: IDL.Func([], [IDL.Text], ["query"]),
    getWallets: IDL.Func([IDL.Nat], [IDL.Vec(WalletAddress)], ["query"]),
    geta: IDL.Func([], [IDL.Nat], ["query"]),
    init: IDL.Func([IDL.Bool], [IDL.Nat], []),
    initialDistributionHour: IDL.Func(
      [],
      [IDL.Record({ hourCountDown: IDL.Int, timestamp: IDL.Int })],
      ["query"]
    ),
    isNotPaused: IDL.Func([], [IDL.Bool], ["query"]),
    isVerified: IDL.Func([IDL.Principal], [IDL.Bool], ["query"]),
    logError: IDL.Func([IDL.Text, IDL.Text], [], []),
    matchRevenue: IDL.Func(
      [IDL.Text, IDL.Text],
      [
        IDL.Record({
          num: IDL.Nat,
          dets: IDL.Vec(DistributionHistory),
        }),
      ],
      []
    ),
    migrateBalance: IDL.Func([IDL.Text, IDL.Text], [IDL.Bool], []),
    migrateJwallet: IDL.Func([], [IDL.Nat], []),
    pauseCanister: IDL.Func([IDL.Bool], [IDL.Bool], []),
    recalculateBalance: IDL.Func(
      [],
      [
        IDL.Record({
          detail: IDL.Vec(
            IDL.Record({
              result: IDL.Text,
              username: IDL.Text,
              amount: IDL.Nat,
            })
          ),
          kurang: IDL.Nat,
          lebih: IDL.Nat,
        }),
      ],
      []
    ),
    recordJwalletId: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Text],
      [IDL.Variant({ ok: IDL.Null, exist: IDL.Text })],
      []
    ),
    routine24Force: IDL.Func([IDL.Bool], [IDL.Text], []),
    saveBankAddress: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Text, IDL.Text],
      [IDL.Bool],
      []
    ),
    saveWalletAddress: IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Bool], []),
    sendCKBTC: IDL.Func([IDL.Text, IDL.Text, IDL.Nat], [IDL.Bool], []),
    setBalance: IDL.Func([IDL.Nat], [IDL.Bool], []),
    setCKBTCVault: IDL.Func([IDL.Principal], [IDL.Principal], []),
    setDappsKey: IDL.Func([IDL.Text], [IDL.Text], []),
    setF2PoolKey: IDL.Func([IDL.Text], [IDL.Text], []),
    setJwalletVault: IDL.Func([IDL.Text], [IDL.Text], []),
    setMinerCKBTCVault: IDL.Func([IDL.Principal], [IDL.Principal], []),
    setT: IDL.Func([IDL.Nat], [IDL.Nat], []),
    setTS: IDL.Func([IDL.Int], [IDL.Bool], []),
    setUsername: IDL.Func(
      [IDL.Text, IDL.Text],
      [IDL.Variant({ ok: MinerData, none: IDL.Nat })],
      []
    ),
    shareRevenue: IDL.Func(
      [IDL.Text, IDL.Nat],
      [
        IDL.Variant({
          res: IDL.Vec(IDL.Tuple(IDL.Text, RevenueShare)),
          success: IDL.Nat,
          failed: IDL.Text,
        }),
      ],
      []
    ),
    specialReward: IDL.Func([IDL.Text], [IDL.Text], []),
    stopScheduler: IDL.Func([IDL.Nat], [IDL.Bool], []),
    toggleRoutine: IDL.Func([IDL.Bool], [IDL.Bool], []),
    transform: IDL.Func(
      [TransformArgs],
      [CanisterHttpResponsePayload],
      ["query"]
    ),
    undoDistribution: IDL.Func([IDL.Text], [IDL.Text], []),
    updateBalance: IDL.Func(
      [],
      [
        IDL.Variant({
          Ok: IDL.Vec(UtxoStatus),
          Err: UpdateBalanceError,
        }),
      ],
      []
    ),
    verifyMiner: IDL.Func([IDL.Text, IDL.Nat], [IDL.Bool], []),
    whoCall: IDL.Func([], [IDL.Text], ["query"]),
    withdrawCKBTC: IDL.Func([IDL.Text, IDL.Nat, IDL.Text], [TransferRes], []),
    withdrawIDR: IDL.Func(
      [IDL.Text, IDL.Nat, IDL.Text, IDL.Vec(IDL.Nat8)],
      [TransferRes],
      []
    ),
    withdrawNativeBTC: IDL.Func(
      [IDL.Text, IDL.Nat, IDL.Text],
      [TransferRes],
      []
    ),
    withdrawUSDT: IDL.Func(
      [IDL.Text, IDL.Nat, IDL.Text, IDL.Text],
      [TransferRes],
      []
    ),
    withdrawalList: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, WithdrawalHistory))],
      ["query"]
    ),
  });
};
export const init = ({ IDL }) => {
  return [];
};
